// import React, { Component } from 'react';
import React, { useState, useRef, useEffect } from "react";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import axios from "axios";
import SliderItem from "../sliderItem/SliderItem";
import { GetAllOffer } from "../../API/OfferAPI";
import { toast } from "react-hot-toast";


const Offer=()=> {

	let publicUrl = process.env.PUBLIC_URL+'/'
	const [packages,setPackages]=useState([])


const GetPackages= async ()=>{
	axios.get(GetAllOffer()).then(res=>{	
		
		let arr = [res?.data?.data.results[11], res?.data?.data.results[12],res?.data?.data.results[13]]
		setPackages(arr)
		console.log("Tour packages",packages)
	}).catch(function (error){
		if(error.response){
			toast.error("Offers failed to load")
		}

	})

}
useEffect(()=>{
GetPackages()

},[])


    return(

    
	  <div className="offer-area pd-top-70">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-xl-6 col-lg-8">
			        <div className="section-title text-center">
			          <h2 className="title">Special Interest Tours</h2>
			          <p>We take onboard that people have different interests when travelling, we at West African Tours can arrange memorable tours.</p>
			        </div>
			      </div>
			    </div>
			  </div>
			  <div className="destinations-list-slider-bg">
			    <div className="container">
			      <div className="row">
			       <SliderItem packages={packages}/>
			        <div className="col-lg-2 align-self-center order-lg-11">
			          <div className="container">
			            <div className="destinations-slider-controls">
			              <div className="slider-nav tp-control-nav" />
			              {/*slider-nav*/}
			              <div className="tp-slider-extra slider-extra">
			                <div className="text">
			                  <span className="first">01 </span>
			                  <span className="last" />
			                </div>
			                {/*text*/}
			                <div className="d-list-progress" role="progressbar" aria-valuemin={0} aria-valuemax={100}>
			                  <span className="slider__label sr-only" />
			                </div>
			              </div>
			              {/*slider-extra*/}
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
	

   


	)
}

export default Offer