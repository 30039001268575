import React from 'react'
import { Link } from 'react-router-dom'
import { IMG_URL } from '../../API/base'
let publicUrl = process.env.PUBLIC_URL + '/'

const SliderItem = ({ packages }) => {
 console.log("slider item ", packages)
  return (
    <div className='col-xl-9 col-lg-10 offset-xl-1 order-lg-12'>
      <div className='destinations-list-slider'>
        <div className='d-list-slider-item'>
          <div className='single-destinations-list text-center'>
            <div className='thumb'>
              <span className='d-list-tag'>Special Offer</span>

              <img src={IMG_URL + packages[0]?.coverImageUrl.replace("/home/images/", "")} alt='list' />
              <div className='d-list-btn-wrap'>
                <div className='d-list-btn viaje-go-top'>
                  <Link
                    className='btn btn-yellow'
                    to={{
                      pathname: `/tour-details/${packages[0]?.id}`,
                      state: packages[1],
                    }}
                  >
                    Book Now <i className='fa fa-paper-plane' />
                  </Link>
                </div>
              </div>
            </div>
            <div className='details'>
              <h4 className='title'>{packages[0]?.name}</h4>
              <p className='content'>
                {packages[0]?.description.substr(0, 100)}
                {'...'}
              </p>
              <ul className='tp-list-meta border-bt-dot'>
                {/* <li>
                  <i className='fa fa-calendar-o' />{' '}
                    {packages[0]?.duration}
                </li> */}
                <li>
                  <i className='fa fa-clock-o' /> {packages[0]?.duration}{' '}
                  {packages[0]?.duration > 1 ? 'days' : 'day' }
                </li>
                <li>
                  <i className='fa fa-star' /> {4}
                </li>
              </ul>
              <div className='tp-price-meta tp-price-meta-cl'>
              <p>Price</p>
                <h2>
                  {0} <small>{packages[0]?.currency}</small>
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className='d-list-slider-item'>
          <div className='single-destinations-list text-center'>
            <div className='thumb'>
              <span className='d-list-tag'>Special Offer</span>

              <img src={IMG_URL + packages[1]?.coverImageUrl.replace("/home/images/", "")} alt='list' />
              <div className='d-list-btn-wrap'>
                <div className='d-list-btn viaje-go-top'>
                  <Link
                    className='btn btn-yellow'
                    to={{
                      pathname: `/tour-details/${packages[1]?.id}`,
                      state: packages[1],
                    }}
                  >
                    Book Now <i className='fa fa-paper-plane' />
                  </Link>
                </div>
              </div>
            </div>
            <div className='details'>
              <h4 className='title'>{packages[1]?.name}</h4>
              <p className='content'>
                {packages[1]?.description.substr(0, 100)}
                {'...'}
              </p>
              <ul className='tp-list-meta border-bt-dot'>
                {/* <li>
                  <i className='fa fa-calendar-o' />{' '}
              
                    {packages[1]?.duration}
                 
                </li> */}
                <li>
                  <i className='fa fa-clock-o' /> {packages[1]?.duration}{' '}
                  {packages[1]?.duration > 1 ? 'days' : 'day' }
                </li>
                <li>
                  <i className='fa fa-star' /> {5}
                </li>
              </ul>
              <div className='tp-price-meta tp-price-meta-cl'>
                <p>Price</p>
                <h2>
                  {0} <small>{packages[1]?.currency}</small>
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className='d-list-slider-item'>
          <div className='single-destinations-list text-center'>
            <div className='thumb'>
              <span className='d-list-tag'>Special Offer</span>

              <img src={IMG_URL + packages[2]?.coverImageUrl.replace("/home/images/", "")} alt='list' />
              <div className='d-list-btn-wrap'>
                <div className='d-list-btn viaje-go-top'>
                  <Link
                    className='btn btn-yellow'
                    to={{
                      pathname: `/tour-details/${packages[2]?.id}`,
                      state: packages[2],
                    }}
                  >
                    Book Now <i className='fa fa-paper-plane' />
                  </Link>
                </div>
              </div>
            </div>
            <div className='details'>
              <h4 className='title'>{packages[2]?.name}</h4>
              <p className='content'>
                {packages[2]?.description.substr(0, 100)}
                {'...'}
              </p>
              <ul className='tp-list-meta border-bt-dot'>
                {/* <li>
                  <i className='fa fa-calendar-o' />{' '}
                
                    {packages[2]?.duration}
                </li> */}
                <li>
                  <i className='fa fa-clock-o' /> {packages[2]?.duration}{' '}
                  {packages[2]?.duration > 1 ? 'days' : 'day' }
                </li>
                <li>
                  <i className='fa fa-star' /> {5}
                </li>
              </ul>
              <div className='tp-price-meta tp-price-meta-cl'>
              <p>Price</p>
                <h2>
                  {0} <small>{packages[2]?.currency}</small>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SliderItem
